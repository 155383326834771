import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Page from '../components/layout/page/page';
import FormattedHtml from '../components/formattedHtml/formattedHtml';
import SliceZone from '../components/sliceZone/sliceZone';
import Heading from '../components/heading/heading';
import Footer from '../components/footer/footer';

const Subpage = ({ pageContext }) => {
  const seoData =
    pageContext.data.body_meta &&
    pageContext.data.body_meta.find(el => el.slice_type === 'seo');

  return (
    <>
      <SEO
        title={seoData.primary.tytul}
        description={seoData.primary.opis}
        url={`/${pageContext.parentSlug}/${pageContext.data.slug}/`}
      />

      <Page
        parentSlug={pageContext.parentSlug}
        pageTitle={pageContext.pageTitle}
        links={pageContext.links}
        bannerImage={pageContext.bannerImage}
        image={pageContext.navigationImage}
      >
        <Heading
          title={pageContext.data.tytul_sekcji.text}
          heading={pageContext.data.naglowek_sekcji.text}
          asText
          css="margin-bottom: 30px;"
          data-aos="fade-up"
          data-aos-delay="200"
        />

        <FormattedHtml
          dangerouslySetInnerHTML={{
            __html: pageContext.data.tresc_sekcji.html,
          }}
          data-aos="fade-up"
          data-aos-delay="300"
        />

        <SliceZone slices={pageContext.data.body} />
      </Page>

      <Footer />
    </>
  );
};

Subpage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Subpage;
